import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  updateTarget(e) {
    const targetSelector = e.currentTarget.dataset.target
    const targetSelect = document.querySelector(targetSelector)

    if (!targetSelect) {
      console.error(`Target element not found for selector: ${targetSelector}`)
      return
    }

    const endpointTemplate = targetSelect.dataset.endpoint
    const parentKey = targetSelect.dataset.parentKey

    if (!endpointTemplate || !parentKey) {
      console.error("Missing endpoint or parent key for the target dropdown.")
      return
    }

    const params = { [parentKey]: e.currentTarget.value }

    // If there's no value, clear the target input.
    if (!params[parentKey]) {
      this.clearOptions(targetSelect)
      return
    }

    const endpoint = this.interpolateEndpoint(endpointTemplate, params)
    this.fetchAndPopulate(targetSelect, endpoint)
  }

  /** FUNCTIONS **/

  clearOptions(target) {
    const includeBlank = target.dataset.includeBlank || "Select an option"
    target.innerHTML = `<option value="">${includeBlank}</option>`
    $(target).trigger("change") // Trigger change event for Select2 reset
  }

  interpolateEndpoint(endpoint, params) {
    return endpoint.replace(/:([a-z_]+)/g, (_, key) => params[key] || "")
  }

  fetchAndPopulate(target, endpoint) {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => this.populateOptions(target, data))
      .catch((error) => console.log("Error fetching options:", error))
  }

  populateOptions(target, options) {
    const includeBlank = target.dataset.includeBlank || "Select an option"
    target.innerHTML = `<option value="">${includeBlank}</option>`

    options.forEach((option) => {
      const optElement = document.createElement("option")
      optElement.value = option.id
      optElement.textContent = option.name
      target.appendChild(optElement)
    })
    $(target).trigger("change") // Trigger change event for Select2
  }
}
